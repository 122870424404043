import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import EventDetails from "../components/event-details";
import PageBuilder from "../components/page-builder";
import SEO from "../components/seo";

export const query = graphql`
  query EventsTemplateQuery($id: String!) {
    event: sanityEvents(id: { eq: $id }) {
      title
      id
      image {
        asset {
          _id
          altText
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      pageSEO {
        metaTitle
        metaDescription
        metaImage {
          asset {
            _id
            altText
          }
          hotspot {
            height
            width
            x
            y
          }
        }
      }
      tileDescription
      startDate
      tags {
        value
      }
      registrationText
      registrationLink
      registrationClosed
      location
      endDate
      isFuture
      disclaimerText
      details {
        costValue
        costHeading
        costDescription
      }
      dates {
        date
        time
      }
      cpdHours
      cpdHoursDescription
      _rawPageBuilder(resolveReferences: { maxDepth: 8 })
      pageBuilder {
        ... on SanityAccordionModule {
          _key
          _type
          heading
          items {
            heading
            _rawBody(resolveReferences: { maxDepth: 8 })
          }
          buttonText
          ...accordionModuleButtonLink
        }
        ... on SanitySponsorModule {
          _key
          _type
          heading
          subheading
          items {
            _type
            _key
            type
            items {
              _type
              icon {
                _key
                _type
                _rawAsset
                _rawHotspot
                _rawCrop
                asset {
                  _id
                  size
                    url
                    title
                    altText
                    gatsbyImageData
                    id
                  }
              }
              externalLink
              title
            }
          }
        }
        ... on SanitySpeakerModule {
          _key
          _type
          heading
          items {
            description
            email
            location
            fullName
            number
            role
            image {
              asset {
                altText
                _id
              }
            }
            linkAddress {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  parentPage {
                    slug {
                      current
                    }
                    parentPage {
                      slug {
                        current
                      }
                    }
                  }
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              route
              link
              pdf {
                asset {
                  url
                }
              }
            }
            linkAddress {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  parentPage {
                    slug {
                      current
                    }
                    parentPage {
                      slug {
                        current
                      }
                    }
                  }
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              link
              route
              pdf {
                asset {
                  url
                }
              }
            }
          }
        }
        ... on SanityContentBlockArray {
          _type
          heading
          block {
            _rawBlock(resolveReferences: { maxDepth: 8 })
          }
        }
      }
    }
  }
`;

const EventsTemplate = props => {
  const { data } = props;
  const event = data && data.event;

  return (
    <Layout className="event-page">
      {!event.isFuture && (
        // Don't index future events
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <SEO
        seo={event.pageSEO}
        title={event.title}
        image={event.image}
        excerpt={event.tileDescription}
      />
      <div className="events-page page">
        <EventDetails event={event} />
        {event.pageBuilder[0] && (
          <PageBuilder pagebuilder={event.pageBuilder} />
        )}
      </div>
    </Layout>
  );
};

export default EventsTemplate;
