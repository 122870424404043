import React from "react";
import eventLocations from "../helper/eventLocations";
import Button from "./button";

const EventDetails = ({ event }) => {
  let dayAfter = new Date(event.endDate);
  dayAfter.setDate(dayAfter.getDate() + 1);
  dayAfter.setHours(0, 0, 0, 0);

  let valid = event.registrationClosed ? false : true;
  if (dayAfter < new Date()) {
    valid = false;
  }
  return (
    <section className="event-details">
      <div className="event-details-inner">
        <h2 className="module-heading">{event.title}</h2>
        <div className="event-details-container">
          <div className="event-schedule">
            {event.dates[0] &&
              event.dates.map((item, i) => {
                return (
                  <div className="single-event-date" key={i}>
                    {item.date && (
                      <p className="body-bold event-date-text">{item.date}</p>
                    )}
                    {item.time && (
                      <p className="caption event-time-text">{item.time}</p>
                    )}
                  </div>
                );
              })}
            {event.disclaimerText && (
              <p className="caption disclaimer-text -desktop">
                {event.disclaimerText}
              </p>
            )}
          </div>
          <div className="event-pricing-container">
            {event.location && (
              <div className="event-location">
                <p className="body-bold">Location</p>
                <p className="body-bold">{eventLocations(event.location)}</p>
              </div>
            )}

            {event.details[0] && (
              <div className="event-pricing">
                {event.details.map((detail, i) => {
                  return (
                    <div className="event-detail-item" key={i}>
                      <div className="cost-description">
                        <p className="event-detail-heading body-bold">
                          {detail.costHeading}
                        </p>
                        {detail.costDescription && (
                          <p className="event-detail-description caption">
                            {detail.costDescription}
                          </p>
                        )}
                      </div>
                      <p className="cost-value body-bold">{detail.costValue}</p>
                    </div>
                  );
                })}
              </div>
            )}
            {(event.cpdHoursDescription || event.cpdHours) && (
              <div className="event-cpd">
                <div className="cpd-hours-heading">
                  <p className="body-bold">CPD</p>
                  {event.cpdHoursDescription && (
                    <p className="cpd-description caption">
                      {event.cpdHoursDescription}
                    </p>
                  )}
                </div>
                {event.cpdHours && (
                  <p className="cpd-value body-bold">{event.cpdHours}</p>
                )}
              </div>
            )}
            {event.registrationLink && (
              <Button
                type={valid ? "primary" : "disabled"}
                button={{
                  link: event.registrationLink,
                  text: event.registrationText,
                }}
              />
            )}
          </div>
        </div>
        {event.disclaimerText && (
          <p className="caption disclaimer-text -mobile">
            {event.disclaimerText}
          </p>
        )}
      </div>
    </section>
  );
};

export default EventDetails;
